@font-face {
  font-family: "f1";
  src: url("../fonts/Ubuntu/Ubuntu-Regular.ttf");
}
@font-face {
  font-family: "f2";
  src: url("../fonts/Ubuntu/Ubuntu-Medium.ttf");
}

@font-face {
  font-family: "f3";
  src: url("../fonts/Ubuntu/Ubuntu-Bold.ttf");
}
