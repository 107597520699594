@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";

body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  background: $background;
  // overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}
.w-90 {
  width: 90%;
  margin: auto;
}
.flex-ac-jc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.flex-ac-tr {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-ac-jb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-ac-je {
  display: flex;
  align-items: center;
  justify-content: end;
}
.flex-ae-jb {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-ae-jc {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.txt-primary1 {
  color: $primary1;
}
.txt-primary2 {
  color: $primary2;
}
.txt-primary3 {
  color: $primary3;
}
.txt-primary4 {
  color: $primary4;
}
.txt-blues {
  color: $blues;
}
.txt-greens {
  color: $greens;
}
.txt-hash2 {
  color: $hash2;
}
.txt-yellows {
  color: $yellows;
}
.txt-background {
  color: $background;
}
.txt-background1 {
  color: $background1;
}
.bg-primary1 {
  background: $primary1;
}
.bg-primary2 {
  background: $primary2;
}
.bg-primary3 {
  background: $primary3;
}
.bg-primary4 {
  background: $primary4;
}
.bg-blues {
  background: $blues;
}
.bg-greens {
  background: $greens;
}
.bg-yellows {
  background: $yellows;
}
.bg-hash2 {
  background: $hash2;
}
.bg-background {
  background: $background;
}
.bg-background1 {
  background: $background1;
}
.bg-danger-gradient {
  background-image: linear-gradient(
    to bottom right,
    #f1bf64 0%,
    #f71d36 100%
  ) !important;
}
.bg-primary-gradient {
  background: linear-gradient(to bottom right, #a29bff 0%, #6259ca) !important;
}
.bg-secondary-gradient {
  background: linear-gradient(
    to bottom right,
    #9070ff 0%,
    #ff5d9e 100%
  ) !important;
}
.bg-success-gradient {
  background: linear-gradient(
    to bottom right,
    #4be8d4 0%,
    #129bd2 100%
  ) !important;
}
.bg-prim-gradient {
  background: linear-gradient(
    to bottom right,
    #6a4be8 0%,
    #bc58ff 100%
  ) !important;
}
.w-90 {
  width: 90%;
}
.menu-icon {
  width: 10px;
}
.comn-btn {
  font-size: 13px;
  color: $white;
  font-family: "f2";
  border: 0;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    filter: grayscale(0.1) brightness(0.8);
    color: $white;
  }
}

.comn-btns {
  font-size: 13px;
  color: $white;
  font-family: "f2";
  border: 0;
  border-radius: 5px;
  text-decoration: none;
}
.menu-bar {
  width: 230px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  z-index: 2;
  background: $white;
  .top-head {
    position: fixed;
    z-index: 11;
    background: $white;
    left: 0;
    height: 60px;
    border: 1px solid $background;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
    text-decoration: none;
    cursor: pointer;
    h5 {
      color: $primary2;
      font-family: "f2";
      font-size: 20px;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  ul {
    padding: 0;
    border-radius: 5px;
    margin-bottom: 5px;
    li {
      list-style: none;
      .menu-list {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-family: "f1";
        text-decoration: none;
        padding: 14px 20px;
        transition: 0.3s all linear;
        color: #000;
        position: relative;
        opacity: 0.9;
        // img {
        //   width: 19px;
        //   margin-right: 10px;
        //   margin-left: 8px;
        // }

        svg {
          vertical-align: middle;
          width: 50px;
          height: 20px;
          color: #000;
        }

        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 0px;
          height: 100%;
          background-color: $primary4;
          transition: 0.1s all linear;
        }
        &:hover {
          background-color: $background;
          transition: 0.3s all linear;
          opacity: 1 !important;
          color: $primary4;
          img {
            opacity: 1 !important;
            filter: invert(0) contrast(20);
          }
          &::after {
            width: 3px;
            transition: 0.1s all linear;
          }
          svg {
            color: $primary1;
          }
        }
      }
      .active {
        transition: 0.3s all linear;
        opacity: 1 !important;
        color: $primary1;
        img {
          opacity: 1 !important;
          filter: invert(0) contrast(20);
        }
        &::after {
          width: 3px;
          transition: 0.1s all linear;
        }
        svg {
          color: $primary1;
        }
      }
    }
  }
}
.menu-bar1 {
  width: 230px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  z-index: 1;
  background: $white;
  .top-head {
    position: fixed;
    z-index: 11;
    background: $white;
    left: 0;
    height: 60px;
    border: 1px solid $background;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
    text-decoration: none;
    h5 {
      color: $hash;
      font-family: "f2";
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  ul {
    padding: 0;
    border-radius: 5px;
    margin-bottom: 5px;
    li {
      list-style: none;
      .menu-list1 {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-family: "f1";
        text-decoration: none;
        padding: 14px 20px;
        transition: 0.3s all linear;
        color: $hash2;
        position: relative;
        opacity: 0.9;
        border: 0;
        background: transparent;
        width: 100%;
        img {
          width: 19px;
          margin-right: 10px;
          margin-left: 8px;
        }
        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 0px;
          height: 100%;
          background-color: $hash;
          transition: 0.1s all linear;
        }
        &:hover {
          background-color: $background;
          transition: 0.3s all linear;
          opacity: 1 !important;
          color: $hash;
          img {
            opacity: 1 !important;
            filter: grayscale(0.7);
          }
          &::after {
            width: 3px;
            transition: 0.1s all linear;
          }
        }
      }
      .active {
        transition: 0.3s all linear;
        opacity: 1 !important;
        color: $hash;
        background-color: $background;
        img {
          opacity: 1 !important;
          filter: grayscale(0.7);
        }
        &::after {
          width: 3px;
          transition: 0.1s all linear;
        }
      }
    }
  }
}
.header-rit-top {
  background: $white;
  height: 60px;
  box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
  z-index: 1;
  .header-logout {
    height: 45px;
    width: 100%;
    border: 0;
    text-align: left;
    padding-left: 15px;
  }
  h4 {
    font-family: "f3";
    margin-bottom: 0;
    font-size: 17px;
    text-transform: uppercase;
  }
  .header-right {
    height: 45px;
    .user-name {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // background: $white;
      // box-shadow: 0px 3px 4px -1px #aaa;
      margin: 0;
      color: #000;
    }
    button {
      padding-right: 0;
    }
    a {
      width: 32px;
      height: 32px;
      border-radius: 25px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      box-shadow: 0px 3px 4px -1px #aaa;
      margin: 0 10px;
      cursor: pointer;
      transition: 0.2s all linear;
      position: relative;
      .notif-status {
        position: absolute;
        top: 6px;
        right: 9px;
        width: 7px;
        height: 7px;
        border-radius: 20px;
        background: red;
        overflow: hidden;
      }
      svg {
        width: 20px;
      }
      &:hover {
        background-color: $primary2;
        transition: 0.2s all linear;
        svg {
          filter: invert(1);
        }
      }
    }
  }
}
.out-left {
  margin-left: 230px;
  background-color: $background;
}

.dash-box {
  border: 0;
  border-radius: 4px;
  padding: 10px;
  background-color: $white;
  box-shadow: 0 1px 5px rgb(229 228 230 / 48%);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  button {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    box-shadow: 0px 3px 4px -1px #aaa;
    border: 0;
    svg {
      width: 30px;
      filter: invert(1);
    }
  }
  h6 {
    font-size: 16px;
    font-family: "f1";
    color: rgb(133, 133, 133);
    margin-bottom: 0px;
  }
  h5 {
    font-size: 19px;
    font-family: "f2";
    margin: 3px 0 5px;
    color: $hash;
  }
  h3 {
    font-size: 30px;
    font-family: "f2";
    color: #ababab;
    margin-bottom: 0;
  }
}
.custom-table {
  width: 100%;
  border: 1px solid $hash3;
  thead {
    tr {
      background: $background;
      th {
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        &:first-child {
          text-align: center;
        }
      }
    }
  }
  tbody {
    tr {
      background: $background1;
      td {
        background: transparent;
        color: $hash;
        font-size: 14px;
        font-family: "f1";
        padding: 8px 12px;
        border-bottom: 1px solid $hash3;
        border-right: 1px solid $hash3;
        min-height: 47px;
        &:first-child {
          width: 10px;
          text-align: center;
        }
        &:last-child {
          border-right: 0 !important;
          // width: 100%;
        }
        img {
          width: 70px;
          margin: auto;
        }
      }
      .txt-t {
        cursor: pointer;
        text-decoration: underline;
        background: none;
        color: #0d6efd;
        text-align: center;
      }
      &:nth-child(even) {
        background: $background;
      }
      .active-deact-btn {
        width: 105px;
        text-align: center;
        padding: 5px 0;
      }
    }
  }
}
.edit-btn-svg {
  width: 29px !important;
  height: 27px !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  display: inline-flex;
  svg {
    width: 13px;
  }
}
.table-modal {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
}

.modal-err {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  margin-left: 0%;
  margin-top: 0%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  .in-modal-box {
    background: rgba(255, 255, 255, 1);
    max-width: 100%;
    border: 0px solid #000;
    box-shadow: 0px 0px 5px #000;
    padding: 20px 50px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    p {
      font-size: 15px;
      padding-right: 5px;
    }
  }
}
.modal-label-input {
  position: relative;
  h4 {
    font-family: "f3";
    font-size: 16px;
    margin-top: 10px;
  }
  label {
    font-size: 13px;
    color: $primary1;
    margin-bottom: 0;
    font-family: "f2";
  }

  .label-off {
    font-size: 13px;
    color: $primary1;
    margin-bottom: 0;
    font-family: "f2";
  }
  .label-offs {
    font-size: 14px;
    color: $primary1;
    margin-bottom: 0;
    font-family: "f2";
  }
  input,
  select,
  textarea,
  .address-request {
    font-size: 13px;
    color: $black;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border-bottom: 1px solid #c7c7c7 !important;
    margin-bottom: 15px;
    border: 0;
    font-family: "f2";
    height: 35px;
    &::placeholder {
      font-family: "f1";
      opacity: 0.7;
      font-size: 13px;
    }
  }
  textarea {
    max-height: 50px !important;
  }
  .eye-btn {
    position: absolute;
    bottom: 21px;
    right: 10px;
    display: grid;
    border: 0;
    background: transparent;
    .log-in-eye {
      width: 20px;
    }
  }
  .err {
    font-size: 15px;
    font-size: 12px;
    color: red;
    position: absolute;
    right: 0;
    margin-bottom: 0;
    bottom: -3px;
  }
}
.table-In-p {
  position: relative;
  margin-bottom: 0;
}
.in-modal-box {
  .err {
    font-size: 12px;
    color: #0008ff;
    bottom: -3px;
  }
}
.search-input {
  input {
    border: 1px solid $hash2;
    &::placeholder {
      font-size: 13px;
      font-family: "f1";
      opacity: 0.6;
    }
  }
}
:focus-visible {
  outline: 0;
}
.textarea-height {
  height: auto !important;
}
.custom-pagi-nation {
  .page-item {
    .page-link {
      background: $primary1;
      border: 0;
      border-radius: 100% !important;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: flex;
      margin: 0 5px;
      color: $white;
      opacity: 0.6;
      font-size: 10px;
      &:first-child,
      :last-child {
        border-radius: 100%;
      }
      &:hover {
        filter: brightness(0.4) contrast(1.2);
      }
    }
  }
  .active {
    .page-link {
      background: $primary2;
      opacity: 1;
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
}
.download-btn {
  width: 85px;
  height: 30px;
  border-radius: 3px;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-family: "f1";
}
.wid {
  width: 150px;
  // margin: auto;
}
.ad-rol-a {
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
.prev-Image {
  border: 1px solid $background;
  background-color: #eff6ff;
  img {
    width: 100% !important;
    display: block;
    margin: auto;
  }
}
.bread-crumb {
  svg {
    width: 5px;
  }
  li {
    list-style: none;
    color: $primary2;
    font-size: 13px;
    font-family: "f1";
    a {
      font-size: 13px;
      color: $primary2;
      text-decoration: none;
      opacity: 0.6;
      svg {
        width: 10px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.permission-text {
  font-family: "f2";
  font-size: 14px;
}
.avatar-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;
}
.roles-page {
  .form-group {
    h6 {
      font-family: "f2";
      font-size: 14px;
      margin-top: 10px;
    }
    .form-check {
      .form-checks-label {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "f1";
        color: $hash2;
        margin-bottom: 10px;
        .form-checks-input,
        input[type="checkbox"] {
          position: relative;
          accent-color: #6a4be8;
          &::before {
            content: "";
            position: absolute;
            // top: 0;
            // left: 0;
            // background: $white;
            // border: 2px solid $hash;
            // width: 15px;
            // height: 15px;
            // border-radius: 2px;
          }
          &:checked::before {
            content: "";
            // width: 15px;
            // height: 15px;
            // border-radius: 2px;
            // border: 3px solid $greens;
            // background-color: $primary2;
            // font-size: 12px;
            // padding: 0;
            // margin: auto;
          }
        }
      }
    }
  }
}
.logo {
  width: 36px;
  margin-right: 8px;
}
// .lft-log {
// }
.tab-appoinment {
  width: 270px;
  li {
    button {
      font-family: "f2";
      font-size: 14px;
      color: $primary1 !important;
    }
  }
}
.message-txt {
  height: auto !important;
}
.log-in {
  .left-log-in {
    position: relative;
    .log-in-left {
      height: auto !important;
      position: absolute;
      bottom: 0;
      width: 100%;
      img {
        width: 90%;
        margin: auto;
        display: flex;
      }
    }
  }
}
.log-logo {
  width: 60px;
}
.login-box {
  box-shadow: 2px 2px 1px 1px #e0e0e0;
  .logo-name {
    font-family: "f3";
    font-size: 21px;
    margin-top: 10px;
    color: $primary2;
  }
}
.sign-in-text {
  font-family: "f2";
  font-size: 16px;
}
.welcome-sign {
  font-family: "f1";
  font-size: 15px;
  margin-top: 5px;
  opacity: 0.5;
}
.chooseTime {
  font-size: 11px;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  background: #6dd291;
  margin-right: 5px;
  filter: grayscale(0.2) brightness(0.8);
}
.choosedTime {
  font-size: 11px;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  background: #6dd291;
  margin-right: 5px;
}
.chooseDate {
  font-size: 11px;
  font-weight: 500;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  margin-right: 5px;
  filter: grayscale(0.2) brightness(0.8);
  background: #eb528a;
}
.choosedDate {
  font-size: 11px;
  font-weight: 500;
  border-radius: 0;
  border: 0;
  padding: 5px;
  margin-bottom: 5px;
  color: #fff;
  margin-right: 5px;
  background: #eb528a;
}

.css-1nmdiq5-menu {
  z-index: 1111 !important;
}

.noti-btn {
  padding-right: 3rem !important;
  padding-left: 14rem !important;
}

.auto-move {
  display: flex;
  align-items: center;
  justify-content: center;
  .auto-img {
    height: 125px;
    width: 125px;
    border-radius: 100%;

    -webkit-animation: action 1s infinite alternate;
    animation: action 1s infinite alternate;
  }

  @-webkit-keyframes action {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes action {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }

  .auto-imgs {
    height: 125px;
    width: 125px;
    border-radius: 100%;
  }
}

.chart-dash {
  p {
    margin: 0;
    font-size: 14px;
  }
  .card {
    height: 100%;
  }
  .form-control {
    padding: 10px;
  }
}
.form-control:focus,
.form-select:focus {
  border-color: $primary1 !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.err-p {
  position: absolute;
  bottom: -32px !important;
  right: 0;
  font-size: 13px !important;
  color: red;
}

.noti-noti {
  p {
    display: flex;
  }
  svg {
    margin-top: 4px;
    color: purple;
  }

  .created_at {
    font-size: 10px;
    margin-top: -13px;
    color: blueviolet;
  }
}

.pwd-container {
  position: relative;
  // width: 295px;
}

.pwd-container input {
  padding: 5px 30px 5px 10px;
  font-size: 14px;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 12px;
}

.pwd-container2 img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: -9px;
  top: 32px;
}

.no-page {
  .selec {
    height: 35px;
    border-radius: 8px;
    background-color: #7b2cbf;
    width: 55px;
    padding: 4px;
  }
}

// SWITCH TOGGLE

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dist-fare {
  span {
    font-size: x-small;
    font-weight: 600;
  }
}

// new

/**  =====================
      Custom css start
==========================  **/
/* ==========  card css start  =========== */
.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  animation: anim-close-card 1.4s linear;
}
@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.card {
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
  }

  .card-header {
    border-bottom: 0px solid #e2e5e8;
    position: relative;

    // + .card-body {
    //   padding-top: 0;
    // }

    h5 {
      margin-bottom: 0;
      color: #222;
      font-size: 14px;
      font-weight: 700;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative;
    }

    .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-block;
      float: right;
      padding: 0;
      position: absolute;
      @media only screen and (max-width: 575px) {
        display: none;
      }

      .dropdown-menu {
        margin-top: 0;

        li {
          cursor: pointer;

          a {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }

      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888;

        i {
          margin-right: 0;
        }

        &:after {
          display: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
      // custom toggler
      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 8px;

        &.mobile-menu span {
          background-color: #888;
          height: 2px;
          border-radius: 5px;

          &:after,
          &:before {
            border-radius: 5px;
            height: 2px;
            background-color: #888;
          }
        }
      }

      .nav-pills {
        padding: 0;
        box-shadow: none;
        background: transparent;
      }
    }
  }

  .card-footer {
    border-top: 1px solid #e2e5e8;
    padding: 12px 25px;

    &:not([class*="bg-"]) {
      background: transparent;
    }
    &[class*="bg-"] {
      border-top: none;
    }
  }

  .card-block,
  .card-body {
    padding: 20px 25px;
  }

  &.card-load {
    position: relative;
    overflow: hidden;

    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(256, 256, 256, 0.7);
      z-index: 999;

      i {
        margin: 0 auto;
        color: #4099ff;
        font-size: 24px;
        align-items: center;
        display: flex;
      }
    }
  }

  &.full-card {
    z-index: 99999;
    border-radius: 0;
  }
}
.pading-table {
  padding: 25px;
}
table {
  thead {
    background: rgb(123 44 191 / 9%);
    font-size: 13px;
    border: 1px solid #d1d2d3;
  }
  th,
  td {
    padding: 10px !important;
  }
}
.table-show {
  p {
    margin: 0;
    font-size: 14px;
  }
}
label {
  font-size: 14px;
  margin: 4px 0;
}

// .seac-box {
//   .css-13cymwt-control {
//     border-color: #7b2cbf;
//     box-shadow: 0 0 0 1px #7b2cbf;
//   }
//   .css-t3ipsp-control:hover {
//     border-color: #7b2cbf;
//   }
// }
.print-logo {
  width: 100px;
  margin: auto;
}

.invoice-btn {
  svg {
    vertical-align: middle;
    width: 50px;
    height: 20px;
    // color: #000;
  }
}

.customPopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  background-color: #0000003f;
}

.customPopupDesign {
  width: 40vw;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  // justify-content: center;
  // align-items: center;
  // display: grid;
}

.customPopupDesign1 {
  width: 40vw;
  height: 40vh;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  // justify-content: center;
  // align-items: center;
  // display: grid;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #86ca17 !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color) !important;
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
