@media (min-width: 768px) {
  .out-left {
    aside {
      .header-rit-top {
        position: fixed;
        width: 100%;
        right: 0;
        padding-left: 250px !important;
      }
    }
  }
  .btm-header-rit-top {
    height: 60px;
  }
}
@media (max-width: 768px) {
  .menu-bar {
    height: 100%;
    .top-head {
      position: initial;
    }
  }
}
