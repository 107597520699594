$primary1: #86ca17;
$primary2: #5ca811;
$primary3: #86ca17;
$primary4: #4caf50;
$blues: #6183e0;
$greens: #6dd291;
$yellows: #dab43f;
$white: #fff;
$black: #000;
$hash: #393939;
$hash2: #767676;
$hash3: #ddd;
$lighthash: #ececec;
$background: #f2f3f9;
$background1: #fdfdfd;
